<template>
  <div class="container">
    <div class="row sb_center">
      <div class="flex s_center">
          <img width="42" height="42" :src="greenLogo" alt="" />
          <span style="font-size:32px" class="green pl10">{{companyConfig.firstName + companyConfig.lastName}}</span>
      </div>
      <h1 v-if="showTitle" class="title">平台服务使用协议</h1>
      <div style="width:180px"></div>
    </div>

    <iframe :src="iframeSrc" frameborder="0"> </iframe>

  </div>
</template>
<script>
import Constant from "@/common/Constant.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      iframeSrc: "",
      showTitle: false
    };
  },
  computed: {
    ...mapState(["companyConfig"]),
    greenLogo() {
      return this.companyConfig.greenLogo
    }
  },
  methods: {
    setParam() {
      const {  params: { status }  } = this.$route;
      const suffix = this.companyConfig.suffixUrl
      switch (status) {
        case "1":
          this.iframeSrc = Constant.AGREEMENT_USE + suffix;
          this.showTitle = true;
          break;
        case "2":
          this.iframeSrc = Constant.AGREEMENT_PRIVACY + suffix;
          this.showTitle = false;
          break;
      }
    }
  },

  created() {
    this.setParam();
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 960px;
  margin: 28px auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  iframe {
    padding: 30px;
    // position: absolute;
    // top: 120px;
    // bottom: 100px;
    // left: 50%;
    height: calc(100vh - 350px);
    margin-top: 20px;
    // margin-left: -480px;
    width: 960px;
    overflow: auto;
    color: #101d37;
    background-color: #ffffff;
    // border-radius: 4px;
    // border: 1px solid #e5e5e6;
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }
}
</style>

